@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

@layer base {
  * {
    font-family: 'Rubik', 'DM Sans', 'Manrope', sans-serif;
  }

  html {
    font-family: 'Rubik', 'DM Sans', 'Manrope', sans-serif;
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Rubik', 'DM Sans', 'Manrope', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .text-gradient {
    background-color: #fff;
    background: linear-gradient(145deg, #2042B8 10.3%, #FF6600 117.2%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-gradient {
    background-color: #0f0e13;
    background-image: linear-gradient(141.55deg, #FF6600 -3.79%, #2042B8 82.82%);
  }

  .bg-gradient-plus {
    background: linear-gradient(135.18deg, #2042B8 10.17%, #FF6600 103.56%);
  }

  .border-gradient {
    border-color: rgb(180 83 9);
  }

  .tab {
    overflow: hidden;
  }

  .tab-content {
    max-height: 0;
    transition: all 0.3s;
  }

  input:checked+.tab-label .test svg {
    transform: rotate(180deg);
  }

  input:checked+.tab-label::after {
    transform: rotate(90deg);
  }

  input:checked~.tab-content {
    max-height: 100vh;
  }
}